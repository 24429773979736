import {controller, target, targets} from '@github/catalyst'
import type FilterInputElement from '@github/filter-input-element'
import type RemotePaginationElement from '../behaviors/remote-pagination-element'

@controller
class ProfilePinsElement extends HTMLElement {
  @target limitNotice: HTMLElement
  @target filterInput: FilterInputElement
  @target input: HTMLInputElement
  @targets filterTypeInputs: HTMLInputElement[]
  @target remotePagination: RemotePaginationElement
  @target form: HTMLFormElement
  @targets checkboxes: HTMLInputElement[]
  // The maximum number of times we should load another page of pinnable items automatically, just
  // when the user wants to filter the list of pinnable items
  autoreloadCount = 100

  private loadMorePinnableItems() {
    if (!this.remotePagination.hasNextPage) return
    if (this.autoreloadCount === 0) return
    this.autoreloadCount--
    this.remotePagination.addEventListener('remote-pagination-load', this.loadMorePinnableItems.bind(this), {
      once: true
    })
    this.remotePagination.loadNextPage()
  }

  private filter() {
    this.input.dispatchEvent(new Event('change', {bubbles: true}))
  }

  // Prevent more than n pinned repository selections.
  async limitPins() {
    // reset event happens before the actual input value reset happens
    // https://html.spec.whatwg.org/#resetting-a-form
    await Promise.resolve()
    const checkboxes = this.checkboxes
    const used = checkboxes.filter(el => el.checked).length
    const limit = parseInt(this.getAttribute('max')!, 10)

    for (const el of checkboxes) {
      el.disabled = used === limit && !el.checked
    }

    const label = this.limitNotice.getAttribute('data-remaining-label') || ''
    const diff = limit - used
    this.limitNotice.textContent = `${diff} ${label}`
    this.limitNotice.classList.toggle('color-text-danger', diff < 1)
  }

  // Disallow last active filter from being unchecked.
  private disableLastCheckedInput() {
    const selectedTypeCheckboxes = this.filterTypeInputs.filter(el => el.checked)

    for (const el of selectedTypeCheckboxes) {
      el.disabled = selectedTypeCheckboxes.length === 1
    }
  }

  private setFilteringLogic() {
    const types = this.filterTypeInputs.filter(el => el.checked).map(el => el.value)

    this.filterInput.filter = (item: HTMLElement, itemText: string, term: string): {match: boolean} => {
      const trimmedTerm = term.toLowerCase().trim()

      if (item.querySelector('input:checked')) return {match: true}

      const matchesQuery = !trimmedTerm || itemText.toLowerCase().indexOf(trimmedTerm.toLowerCase()) > -1
      const type = item.getAttribute('data-pinnable-type')
      const matchesType = types.length > 0 && type ? types.includes(type) : true

      return {match: matchesQuery && matchesType}
    }
  }
}
